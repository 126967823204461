import './VzDetails.css';

import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ILabelVerkehrszeichen } from '../modules/Labels/ILabelVerkehrszeichen';
import { VerkehrszeichenRepo } from '../modules/Repositories';
import { SprachKontext } from '../modules/Sprache/SprachKontext';
import { Verkehrszeichen } from '../modules/Vehrkehrszeichen';
import { VzAbbildung } from './VzAbbildung';

interface IProps {
  verkehrszeichenRepo: VerkehrszeichenRepo;
  landscape: boolean;
}

/*const landscape = {
  breite: "auto",
  hoehe: "50vh",
  flex: "row-reverse" as "row-reverse",
};
const portrait = { breite: "70vw", hoehe: "auto", flex: "column" as "column" };*/

function VzDetails(props: IProps) {
  const [searchParams] = useSearchParams();
  const label: ILabelVerkehrszeichen =
    useContext(SprachKontext).verkehrszeichen;
  const szId = searchParams.get("sz_id");
  let vz: Verkehrszeichen;
  if (szId === null) {
    vz = props.verkehrszeichenRepo.fuerSzId("null");
  } else {
    vz = props.verkehrszeichenRepo.fuerSzId(szId);
  }

  let ueberschrift = label.beschreibungKurz(vz);
  const beschreibung = label.beschreibungLang(vz);

  /*let ausrichtung;
  if (props.landscape) {
    ausrichtung = landscape;
  } else {
    ausrichtung = portrait;
  }*/

  return (
    <div className="Vz">
      <div className="Title">
        <span>{ueberschrift}</span>
      </div>

      <div className="Content">
        <div className="Description">
          <span>{beschreibung}</span>
        </div>
        <div className="Image">
          <VzAbbildung vz={vz} />
        </div>
      </div>
    </div>
  );
}

export { VzDetails };
