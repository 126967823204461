import { BildEinbettung, Verkehrszeichen } from './Verkehrszeichen';
import { VzKategorie } from './VzKategorie';

class VerkehrszeichenFactory {
  public static fromJsonObject(
    kategorie: VzKategorie,
    jsonObject: any
  ): Verkehrszeichen {
    jsonObject[
      "bildUrl"
    ] = `Ressourcen/Verkehrszeichen/svg/${jsonObject["bildUrl"]}`;
    if ("bildEinbettung" in jsonObject) {
      jsonObject["bildEinbettung"] = jsonObject[
        "bildEinbettung"
      ] as BildEinbettung;
    } else {
      jsonObject["bildEinbettung"] = "svgImage" as BildEinbettung;
    }
    return VerkehrszeichenFactory.erzeugeZeichen(
      jsonObject["id"],
      kategorie,
      jsonObject["bildUrl"],
      jsonObject["bildEinbettung"],
      jsonObject["szCodeDe"],
      jsonObject["szCodeCevni"],
      jsonObject["szDbCode"],
      jsonObject["beschreibungKurzDe"],
      jsonObject["beschreibungLangDe"],
      jsonObject["beschreibungKurzCevni"],
      jsonObject["beschreibungCevni"],
      jsonObject["paragraphen"]
    );
  }

  public static unbekanntesVkz(id: string): Verkehrszeichen {
    return VerkehrszeichenFactory.erzeugeZeichen(
      id,
      VzKategorie.UNBEKANNTE_KATEGORIE,
      "",
      "svgImage" as BildEinbettung,
      "",
      "",
      "",
      "Unbekannte ID",
      `Der ID <${id}> ist kein Verkehrszeichen zugeordnet`,
      `Unkown ID`,
      `ID <${id}> is not assigned to a Sign`,
      []
    );
  }

  private static erzeugeZeichen(
    id: string,
    kategorie: VzKategorie,
    bildUrl: string,
    bildEinbettung: BildEinbettung,
    szCodeDE: string,
    szCodeCevni: string,
    szDbCode: string,
    beschreibungKurzDe: string,
    beschreibungLangDe: string,
    beschreibungKurzCevni: string,
    beschreibungCevni: string,
    paragraphen: string[]
  ): Verkehrszeichen {
    const zeichen = new Verkehrszeichen(id, kategorie);
    zeichen.setBildUrl(bildUrl);
    zeichen.setBildEinbettung(bildEinbettung);
    zeichen.setSzCodeDE(szCodeDE);
    zeichen.setSzCodeCevni(szCodeCevni);
    zeichen.setSzDbCode(szDbCode);
    zeichen.setBeschreibungKurzDe(beschreibungKurzDe);
    zeichen.setBeschreibungLangDe(beschreibungLangDe);
    zeichen.setBeschreibungKurzCevni(beschreibungKurzCevni);
    zeichen.setBeschreibungCevni(beschreibungCevni);
    zeichen.setParagraphen(paragraphen);
    return zeichen;
  }
}
export { VerkehrszeichenFactory };
