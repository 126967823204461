import './VzListe.css';

import { Badge, Button, List, Space } from 'antd-mobile';
import { FilterOutline } from 'antd-mobile-icons';
import React, { Component, Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { RepoFilter } from '../modules/Repositories';
import { ISprachLabel } from '../modules/Sprache';
import { SprachKontext } from '../modules/Sprache/SprachKontext';
import { Verkehrszeichen } from '../modules/Vehrkehrszeichen';
import { ThemeContext } from './theme-context';
import { VzAbbildung } from './VzAbbildung';

interface IProps {
  liste: Array<Verkehrszeichen>;
  auswahl: Verkehrszeichen;
  handleAuswahl: any;
  navigate: any;
  label: ISprachLabel;
  theme: any;
  repoFilter: RepoFilter;
}
interface IState {}

class VzListeNav extends Component<IProps, IState> {
  listLabels = this.props.label.verkehrszeichenListe;
  vzLables = this.props.label.verkehrszeichen;
  theme = this.props.theme;
  repoFilter = this.props.repoFilter;
  headerTabs = [
    {
      key: "filter",
      title: "Filter",
      icon: <FilterOutline fontSize={"var(--adm-outline-font-size)"} />,
    },
  ];
  auswahlId = this.props.auswahl?.getId();
  scrollRef: React.RefObject<HTMLElement>;

  constructor(props: IProps) {
    super(props);
    this.scrollRef = React.createRef();
  }

  componentDidMount(): void {
    this.scrollRef.current?.scrollIntoView();
  }

  render() {
    this.listLabels = this.props.label.verkehrszeichenListe;
    this.vzLables = this.props.label.verkehrszeichen;
    this.theme = this.props.theme;
    return (
      <Fragment>
        <div className="header">
          <h2>
            <Space>
              {this.renderFilter()}
              {this.listLabels.titel}
            </Space>
          </h2>
        </div>
        <div className="content">
          <List mode="card">
            {this.repoFilter.getVzFiltered().map(this.renderItem)}
          </List>
        </div>
      </Fragment>
    );
  }

  renderItem = (vz: Verkehrszeichen) => {
    const szId = vz.getId();
    const prefix = (
      <div className="EintragPraefixIcon">
        <VzAbbildung vz={vz} />
      </div>
    );

    const titel = this.vzLables.beschreibungKurz(vz);

    let ref = null;
    if (szId === this.auswahlId) {
      ref = this.scrollRef;
    }

    return (
      <span ref={ref} id={szId} key={szId}>
        <List.Item
          prefix={prefix}
          children={titel}
          arrow={true}
          clickable={true}
          onClick={(event: React.MouseEvent) => {
            this.props.handleAuswahl(vz, this.props.navigate, event);
          }}
        />
      </span>
    );
  };

  renderFilter = () => {
    const badgeText = `${this.repoFilter.getActiveCount()}/${this.repoFilter.getAvailableCount()}`;
    const filterButtonLabel = this.listLabels.filter;
    return (
      <Fragment>
        <Badge
          content={badgeText}
          color={this.theme.elwisColor}
          style={{ "--right": "50%" }}
        >
          <Button
            type="button"
            shape="rounded"
            size="middle"
            fill="none"
            onClick={this.filterChangeHandler}
            aria-label={filterButtonLabel}
          >
            <FilterOutline
              style={{
                color: this.theme.elwisColor,
                fontSize: "var(--adm-outline-font-size)",
              }}
            />
          </Button>
        </Badge>
      </Fragment>
    );
  };

  filterChangeHandler = () => {
    this.props.navigate({ pathname: "/filter" });
  };
}

function VzListe(props: any) {
  const navigate = useNavigate();
  const label: ISprachLabel = useContext(SprachKontext);
  const theme = useContext(ThemeContext);
  return (
    <VzListeNav {...props} navigate={navigate} label={label} theme={theme} />
  );
}

export { VzListe };
