import { VzKategorie } from './VzKategorie';

type BildEinbettung = "svgImage" | "svgSymbol";
class Verkehrszeichen {
  private id: string;
  private kategorie: VzKategorie;
  private bildUrl: string = "";
  private bildEinbettung: BildEinbettung = "svgImage";
  private szCodeDE: string = "";
  private szCodeCevni: string = "";
  private szDbCode: string = "";
  private beschreibungKurzDe: string = "";
  private beschreibungLangDe: string = "";
  private beschreibungKurzCevni: string = "";
  private beschreibungCevni: string = "";
  private paragraphen: string[] = [];

  constructor(id: string, kategorie: VzKategorie) {
    this.id = id;
    this.kategorie = kategorie;
  }

  public getKategorieId(): string {
    return this.kategorie.getId();
  }

  public isKategorie(kategorieId: string): boolean {
    return this.kategorie.getId() === kategorieId;
  }

  public getKategorieDe(): string {
    return this.kategorie.getBeschreibungDe();
  }

  public getKategorieEn(): string {
    return this.kategorie.getBeschreibungEn();
  }

  public getId(): string {
    return this.id;
  }

  public setId(id: string) {
    this.id = id;
  }

  public getBildUrl(): string {
    return this.bildUrl;
  }
  public setBildUrl(value: string) {
    this.bildUrl = value;
  }

  public getBildEinbettung(): BildEinbettung {
    return this.bildEinbettung;
  }
  public setBildEinbettung(value: BildEinbettung) {
    this.bildEinbettung = value;
  }

  public getSzCodeDE(): string {
    return this.szCodeDE;
  }
  public setSzCodeDE(value: string) {
    this.szCodeDE = value;
  }

  public getSzCodeCevni(): string {
    return this.szCodeCevni;
  }
  public setSzCodeCevni(value: string) {
    this.szCodeCevni = value;
  }

  public getSzDbCode(): string {
    return this.szDbCode;
  }
  public setSzDbCode(value: string) {
    this.szDbCode = value;
  }

  public getBeschreibungKurzDe(): string {
    return this.beschreibungKurzDe;
  }
  public setBeschreibungKurzDe(value: string) {
    this.beschreibungKurzDe = value;
  }

  public getBeschreibungLangDe(): string {
    return this.beschreibungLangDe;
  }
  public setBeschreibungLangDe(value: string) {
    this.beschreibungLangDe = value;
  }

  public getBeschreibungCevni(): string {
    return this.beschreibungCevni;
  }
  public setBeschreibungCevni(value: string) {
    this.beschreibungCevni = value;
  }

  public getBeschreibungKurzCevni(): string {
    return this.beschreibungKurzCevni;
  }
  public setBeschreibungKurzCevni(value: string) {
    this.beschreibungKurzCevni = value;
  }

  public getParagraphen(): string[] {
    return this.paragraphen;
  }
  public setParagraphen(value: string[]) {
    this.paragraphen = value;
  }
}
export { Verkehrszeichen };
export type { BildEinbettung };
