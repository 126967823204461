import { useContext } from 'react';

import { ILabelVerkehrszeichen } from '../modules/Labels';
import { SprachKontext } from '../modules/Sprache';
import { Verkehrszeichen } from '../modules/Vehrkehrszeichen';

interface IProps {
  vz: Verkehrszeichen;
}

function VzAbbildung(props: IProps) {
  const label: ILabelVerkehrszeichen =
    useContext(SprachKontext).verkehrszeichen;

  const src = props.vz.getBildUrl();
  let altText = label.altText;
  const code = props.vz.getSzCodeDE();
  if (code !== "") {
    altText = `${altText} ${code}`;
  }

  let abbildung;
  if (props.vz.getBildEinbettung() !== "svgSymbol") {
    abbildung = renderImg(src, altText);
  } else {
    const id = props.vz.getId();
    abbildung = renderSvgSymbol(id, src, altText);
  }

  return abbildung;
}

function renderImg(src: string, alt: string) {
  return <img src={src} alt={alt} />;
}

function renderSvgSymbol(id: string, src: string, altText: string) {
  const titleId = `svg-title_${id}`;
  return (
    <svg role="img" aria-labelledby={titleId}>
      <title id={titleId}>{altText}</title>
      <use href={src} />
    </svg>
  );
}

export { VzAbbildung };
