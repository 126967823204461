import { Badge, CheckList } from 'antd-mobile';
import { Fragment, useContext } from 'react';

import { RepoFilter } from '../modules/Repositories';
import { ISprachLabel, SprachKontext } from '../modules/Sprache';
import { ThemeContext } from './theme-context';

interface IProps {
  filter: RepoFilter;
  changeHandler: () => void;
}

function VzFilter(props: IProps) {
  const labels: ISprachLabel = useContext(SprachKontext);
  const kategorieLabels = labels.kategorie;
  const vzListeLabels = labels.verkehrszeichenListe;

  const theme = useContext(ThemeContext);

  const activeFilterIds = props.filter.getActiveFilterIds();
  //const badgeText = `${props.filter.getActiveCount()}/${props.filter.getAvailableCount()}`;
  const badgeText = `${props.filter.getVzFilteredCount()}/${props.filter.getVzAllCount()}`;
  return (
    <Fragment>
      <div className="header">
        <h2>
          <Badge
            content={badgeText}
            color={theme.elwisColor}
            style={{ "--right": "-10px" }}
          >
            <span>{vzListeLabels.filter}</span>
          </Badge>
        </h2>
      </div>
      <div className="content">
        <CheckList
          multiple
          defaultValue={activeFilterIds}
          onChange={(filterIds) => {
            props.filter.applyFilters(filterIds as string[]);
            props.changeHandler();
          }}
        >
          {props.filter.getCategories().map((category) => {
            const filterName = `${kategorieLabels.beschreibung(
              category
            )} (${category.anzahl()})`;
            const key = category.getId();
            return createItem(filterName, key);
          })}
        </CheckList>
      </div>
    </Fragment>
  );
}

function createItem(filterName: string, key: string) {
  return (
    <CheckList.Item key={key} value={key}>
      {filterName}
    </CheckList.Item>
  );
}

export { VzFilter };
